<template>
    <div class="container-fluid mb-4">
        <CRow>
            <CCol sm="12" v-if="collapseItems">
                <dataTableExtended
                    class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    :loading="apiStateLoading"
                    column-filter
                    :table-filter="tableTextHelpers.tableFilterText"
                    :items-per-page-select="tableTextHelpers.itemsPerPageText"
                    :items="dateFilteredItems"
                    :fields="fields"
                    :noItemsView="tableTextHelpers.noItemsViewText"
                    :items-per-page="5"
                    pagination
                >
                    <template #Nro="{index}">
                        <td class="center-cell text-center">
                            {{index+1}}
                        </td>
                    </template>
                    <template #Action="{item}">
                        <td>
                            <CButtonGroup>
                                <CButton
                                    square
                                    color="watch"
                                    size="sm"
                                    v-c-tooltip="{content: $t('label.confirmedCOntainersDetails') ,
                                        placement: 'top-end'}"
                                    @click="updateCollapse(item)"
                                >
                                    <CIcon name="cil-list"/>
                                </CButton>
                                <CButton
                                    square
                                    color="warning"
                                    size="sm"
                                    class="mr-1"
                                    v-c-tooltip="{
                                        content: $t('label.reverse'),
                                        placement: 'top-end'
                                    }"
                                    v-if="item.ConfirmedJson.length !== 0"
                                    @click="updateConfirmed(item)"
                                >
                                    <CIcon name="cil-reload"/>
                                </CButton>
                            </CButtonGroup>
                        </td>
                    </template>
                    <template slot="under-table">
                        <div v-if="dateFilteredItems.length !== 0">
                            <h6>{{$t('label.totalQuantity')+': '+dateFilteredItems.length}}</h6>
                        </div>
                    </template>
                </dataTableExtended>
            </CCol>
            <CCol sm="12" v-else>
                <CRow>
                    <CCol sm="12">
                        <CRow style="margin-bottom:10px;margin-top:10px;">
                            <CCol sm="7">
                                <div class="d-flex align-items-center">
                                    <h5 class="mt-2">{{$t('label.confirmedContainers')}}</h5>
                                </div>
                            </CCol>
                            <CCol sm="5" class="d-flex align-items-center justify-content-end">
                                <CButton
                                    color="excel"
                                    @click="onBtnExport(true)"
                                >
                                    <CIcon name="cil-file-excel"/>&nbsp; XLSX
                                </CButton>
                                <CButton 
                                    @click="desactivar()" 
                                    color="wipe"
                                    class="ml-2 "
                                    :disabled="apiStateLoading"
                                >
                                    <CIcon name="returnSolid" />
                                    <span class="mx-2">{{$t('button.return')}}</span>
                                </CButton>
                            </CCol>
                        </CRow>
                    </CCol>
                    <CCol sm="12">
                        <dataTableExtended
                            class="align-center-row-datatable"
                            hover
                            sorter
                            small
                            column-filter
                            :table-filter="tableTextHelpers.tableFilterText"
                            :items-per-page-select="tableTextHelpers.itemsPerPageText"
                            :items="dateFilteredItemsSmall"
                            :fields="fieldsSmall"
                            :noItemsView="tableTextHelpers.noItemsViewText"
                            :items-per-page="5"
                            pagination
                        >
                            <template #RowNumber="{index}">
                                <td class="center-cell text-center">
                                    {{index+1}}
                                </td>
                            </template>
                            <template slot="under-table">
                                <div v-if="dateFilteredItemsSmall.length !== 0">
                                    <h6>{{$t('label.totalQuantity')+': '+dateFilteredItemsSmall.length}}</h6>
                                </div>
                            </template>
                        </dataTableExtended>
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import { DateFormater } from '@/_helpers/funciones';
    import Reportes from '@/_mixins/reportes';
    function data() {
        return {
            listItems : [],
            listItemsSmall:[],
            EditName: "",
            collapseItems: true,
        }
    }
    function updateCollapse(item){
        if(item.ConfirmedJson !== null && item.ConfirmedJson !== undefined){
            this.listItemsSmall = item.ConfirmedJson;
            this.EditName = item.EdiFileName;
            this.collapseItems = false;
        }
    }
    function desactivar(){
        this.listItemsSmall = [];
        this.EditName = "";
        this.collapseItems = true;
    }
    async function onBtnExport(valor){
        this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;

        if(this.dateFilteredItems.length !== 0){
            let arreglo = [];
            this.listItemsSmall.forEach(function (item) {
                arreglo.push({
                    '#': item.RowNumber ? item.RowNumber : "",
                    'CONTAINER CODE': item.ContainerCode ? item.ContainerCode : "",
                    'SIZE (FT)': item.TpCargoCode ? item.TpCargoCode : 0,
                    'STATUS': item.Status ? item.Status : "",
                    'CARRIER': item.ShippingLineCode ? item.ShippingLineCode : "",
                    'ISO CODE': item.IsoCode ? item.IsoCode : "",
                    'IMDG': item.ImdgCode ? item.ImdgCode : "",
                    POL: item.LoadPort ? item.LoadPort : "",
                    POD: item.DischargePort ? item.DischargePort : "",
                    POT: item.TranshipmentPort ? item.TranshipmentPort : "",
                    'SEAL 1': item.Seal1 ? item.Seal1 : "",
                    'SEAL 2': item.Seal2 ? item.Seal2 : "",
                    'SEAL 3': item.Seal3 ? item.Seal3 : "",
                    'SEAL 4': item.Seal4 ? item.Seal4 : "",
                    'WEIGHT': item.Weigth ? item.Weigth : 0,
                    VGM: item.Vgm ? item.Vgm : 0,
                    'VGM CERTIFICATE': item.VgmCertificate ? item.VgmCertificate : "", 
                    'BAY SLOT': item.BaySlot ? item.BaySlot : "",
                    'CRANE' : item.CraneName ? item.CraneName : "",
                    'STOWAGE EVENT NAME': item.StowageEventName ? item.StowageEventName : "",
                    'DATE': item.fecharegistrotransa ? item.fecharegistrotransa : "N/A",
                    'DEVICE': item.DeviceName ? item.DeviceName : "",
                })
            });
            await this.getExcelArray(arreglo,this.EditName,valor);
            this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
        }
    }
    //computed
    function dateFilteredItems(){
        let label = this.$i18n.locale;
        if(this.value == 1){
            this.listItems.map(async(item) => {
                item.textStatus = label == "es" ? item.ValueDsEs : item.ValueDsEn;
                item.fecharegistrotransa = item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A';
            })
            return this.listItems;
        }
        return [];
    }
    function dateFilteredItemsSmall(){
        let label = this.$i18n.locale;
        if(!this.collapseItems){
            this.listItemsSmall.map(async(item,index) => {
                item.RowNumber= index+1;
                item.ContainerCode= item.ContainerCode ? item.ContainerCode : "";
                item.Size= item.Size ? item.Size : 0;
                item.Status= item.Status ? item.Status : "";
                item.ShippingLineCode= item.ShippingLineCode ? item.ShippingLineCode : "";
                item.IsoCode= item.IsoCode ? item.IsoCode : "";
                item.ImdgCode= item.ImdgCode ? item.ImdgCode : "";
                item.LoadPort= item.LoadPort ? item.LoadPort : "";
                item.DischargePort= item.DischargePort ? item.DischargePort : "";
                item.TranshipmentPort= item.TranshipmentPort ? item.TranshipmentPort : "";
                item.Seal1= item.Seal1 ? item.Seal1 : "";
                item.Seal2= item.Seal2 ? item.Seal2 : "";
                item.Seal3= item.Seal3 ? item.Seal3 : "";
                item.Seal4= item.Seal4 ? item.Seal4 : "";
                item.Weigth= item.Weigth ? item.Weigth : 0;
                item.Vgm= item.Vgm ? item.Vgm : 0;
                item.VgmCertificate= item.VgmCertificate ? item.VgmCertificate : "";
                item.BaySlot= item.BaySlot ? item.BaySlot : "";
                item.DeviceName= item.DeviceName ? item.DeviceName : "";
                item.CraneName= item.CraneName ? item.CraneName : "";
                item.StowageEventName = label == "es" ? item.StowageEventNameEs : item.StowageEventNameEn;
                item.fecharegistrotransa = item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A';
            });
            return this.listItemsSmall;
        }
        return [];
    }
    function fields(){
        return [
            { key: 'Nro', label: '#',filter :false, _style:'width:1%; text-align: center;' },
            { key: 'EdiFileName', label: this.$t('label.file'),_classes:"center-cell text-left", _style:'min-width:150px;text-align: left;'},
            { key: 'Confirmed', label: this.$t('label.confirmedContainers'),_classes:"center-cell"},
            { key: 'PendingConfirmation', label: this.$t('label.tobeconfirmed'),_classes:"center-cell"},
            { key: 'Planning', label: this.$t('label.totalContainers'),_classes:"center-cell"},
            { key: 'fecharegistrotransa', label:this.$t('label.date'), filter:false,_classes:'center-cell',_style:'width:10%;'},
            { key: 'TransaLogin', label: this.$t('label.user'),_classes:"center-cell" },
            { key: 'textStatus', label: this.$t('label.status'),filter :false,_classes:'center-cell',_style:'width:5%;' },
            { 
                key: 'Action',
                _style:'width:50px;text-align: center;',
                _classes:"center-cell",
                label: '', 
                sorter: false, 
                filter: false
            }
        ]
    }
    function fieldsSmall(){
        return [
            { key: 'RowNumber', label: '#',filter :false, _style:'width:1%; text-align: center;' },
            { key: 'ContainerCode', label: this.$t('label.containerCode'),_classes:"center-cell text-left", _style:'min-width:150px;text-align: left;'},
            { key: 'Size', label: this.$t('label.size')+' (FT)',_classes:"center-cell"},
            { key: 'Status', label: this.$t('label.status'),_classes:"center-cell"},
            { key: 'ShippingLineCode', label: this.$t('label.carrier'),_classes:"center-cell"},
            { key: 'IsoCode', label: this.$t('label.isoCode'),_classes:"center-cell"},
            { key: 'ImdgCode', label: 'IMDG',_classes:"center-cell"},
            { key: 'LoadPort', label: this.$t('label.loadPort'),_classes:"center-cell", _style:'min-width:150px;'},
            { key: 'DischargePort', label: this.$t('label.dischargePort'),_classes:"center-cell", _style:'min-width:150px;'},
            { key: 'TranshipmentPort', label: this.$t('label.transhipmentPort'),_classes:"center-cell", _style:'min-width:150px;'},
            { key: 'Seal1', label: this.$t('label.Seal')+' 1',_classes:"center-cell"},
            { key: 'Seal2', label: this.$t('label.Seal')+' 2',_classes:"center-cell"},
            { key: 'Seal3', label: this.$t('label.Seal')+' 3',_classes:"center-cell"},
            { key: 'Seal4', label: this.$t('label.Seal')+' 4',_classes:"center-cell"},
            { key: 'Weigth', label: this.$t('label.weight'),_classes:"center-cell"},
            { key: 'Vgm', label: 'VGM',_classes:"center-cell"},
            { key: 'VgmCertificate', label: this.$t('label.certificateVGM'),_classes:"center-cell text-left", _style:'min-width:150px;text-align: left;'},
            { key: 'BaySlot', label: "BAYSLOT",_classes:"center-cell"},
            { key: 'CraneName', label: this.$t('label.crane'),_classes:"center-cell"},
            { key: 'StowageEventName', label: this.$t('label.stowageMovement'),_classes:"center-cell"},
            { key: 'fecharegistrotransa', label:this.$t('label.date'), filter:false,_classes:'center-cell',_style:'width:10%;'},
            { key: 'DeviceName', label: this.$t('label.device'),_classes:"center-cell"},
        ]
    }
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function tableTextHelpers(){
        return {
            tableFilterText: {
                label: this.$t('label.filter'),
                placeholder: this.$t('label.enterSearch'),
            },
            itemsPerPageText: {
                label: this.$t('label.recordsPerPage'),
            },
            noItemsViewText: {
                noResults: this.$t('label.noResultsFound'),
                noItems: this.$t('label.noRecordsAvailble'),
            },
        }
    }
    function value(newValue){ 
        if(newValue == 1){
            this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
            let valores = {
                StowagePlanningId:  this.StowagePlanning,
                CompanyBranchId:  this.branchSelected.CompanyBranchId,
            }
            this.$http.ejecutar('POST', 'StowagePlanningEdiFileTransfer-list',valores, { root: 'StowagePlanningFileJson' })
            .then(
            responseAll => {
                if(responseAll.status === 200){
                    let response = responseAll.data.data;
                    let keys = Object.keys(response[0]);
                    if(keys[0] === 'Json'){
                        this.listItems = []
                    }else{
                        this.listItems = response;
                    }
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                }else{  
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                }
            },error => {
                this.$store.commit('planificacionestiba/messageMutation', error);
                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
            });
        }else{
            this.listItems = [];
        }
    }

    export default {
        name:'archivos-transferidos',
        props:['value'],
        data,
        methods:{
            updateCollapse,
            desactivar,
            onBtnExport
        },
        mixins: [Reportes],
        beforeDestroy(){
            this.listItems = [];
        },
        computed:{
            fieldsSmall,
            tableTextHelpers,
            dateFilteredItems,
            dateFilteredItemsSmall,
            fields,
            apiStateLoading,
            ...mapState({
                user: state => state.auth.user,
                branchSelected: state => state.auth.branch,
                apiState: (state) => state.planificacionestiba.apiState,
                apiStateForm: (state) => state.planificacionestiba.apiStateForm,
                EdiFileId: (state) => state.planificacionestiba.EdiFileId,
                EdiFileInfo: (state) => state.planificacionestiba.EdiFileInfo,
                StowagePlanning: state => state.planificacionestiba.planificacionId,
                myDataItinirary: state => state.planificacionestiba.myDataItinirary,
                ItineraryId: state => state.planificacionestiba.ItineraryId,
            })
        },
        watch:{
            value
        }
    }
</script>